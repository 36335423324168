import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

const Seki = ({ name }) => {
    return (
        <div className="seki">
            {name}
        </div>
    )
}

export default Seki;