import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import { Tabs, Tab, Form, FormControl, Row, Col, Button } from 'react-bootstrap';

const Settings = ({ SettingMember, setNumber, sekigae, setTable }) => {

    return (
        <>
            <Tabs
                defaultActiveKey="arrangement"
                className="mb-3"
            >
                <Tab eventKey="arrangement" title="人数とテーブル">
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            人数
                        </Form.Label>
                        <Col sm="auto">
                            <Form.Select onChange={(e) => setNumber(Number(e.target.value))}>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                            </Form.Select>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            テーブル数
                        </Form.Label>
                        <Col sm="auto">
                            <Form.Select onChange={(e) => setTable(Number(e.target.value))}>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                            </Form.Select>
                        </Col>
                    </Form.Group>
                </Tab>
                <Tab eventKey="member" title="メンバー">
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            メンバー1
                        </Form.Label>
                        <Col sm="auto">
                            <FormControl
                                placeholder="名前を入力"
                                key="1"
                                onChange={(e) => SettingMember(e.target.value, 0)}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            メンバー2
                        </Form.Label>
                        <Col sm="auto">
                            <FormControl
                                placeholder="名前を入力"
                                key="2"
                                onChange={(e) => SettingMember(e.target.value, 1)}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            メンバー3
                        </Form.Label>
                        <Col sm="auto">
                            <FormControl
                                placeholder="名前を入力"
                                key="3"
                                onChange={(e) => SettingMember(e.target.value, 2)}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            メンバー4
                        </Form.Label>
                        <Col sm="auto">
                            <FormControl
                                placeholder="名前を入力"
                                key="4"
                                onChange={(e) => SettingMember(e.target.value, 3)}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            メンバー5
                        </Form.Label>
                        <Col sm="auto">
                            <FormControl
                                placeholder="名前を入力"
                                key="5"
                                onChange={(e) => SettingMember(e.target.value, 4)}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            メンバー6
                        </Form.Label>
                        <Col sm="auto">
                            <FormControl
                                placeholder="名前を入力"
                                key="6"
                                onChange={(e) => SettingMember(e.target.value, 5)}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            メンバー7
                        </Form.Label>
                        <Col sm="auto">
                            <FormControl
                                placeholder="名前を入力"
                                key="7"
                                onChange={(e) => SettingMember(e.target.value, 6)}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            メンバー8
                        </Form.Label>
                        <Col sm="auto">
                            <FormControl
                                placeholder="名前を入力"
                                key="8"
                                onChange={(e) => SettingMember(e.target.value, 7)}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            メンバー9
                        </Form.Label>
                        <Col sm="auto">
                            <FormControl
                                placeholder="名前を入力"
                                key="9"
                                onChange={(e) => SettingMember(e.target.value, 8)}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            メンバー10
                        </Form.Label>
                        <Col sm="auto">
                            <FormControl
                                placeholder="名前を入力"
                                key="10"
                                onChange={(e) => SettingMember(e.target.value, 9)}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            メンバー11
                        </Form.Label>
                        <Col sm="auto">
                            <FormControl
                                placeholder="名前を入力"
                                key="11"
                                onChange={(e) => SettingMember(e.target.value, 10)}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            メンバー12
                        </Form.Label>
                        <Col sm="auto">
                            <FormControl
                                placeholder="名前を入力"
                                key="12"
                                onChange={(e) => SettingMember(e.target.value, 11)}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            メンバー13
                        </Form.Label>
                        <Col sm="auto">
                            <FormControl
                                placeholder="名前を入力"
                                key="13"
                                onChange={(e) => SettingMember(e.target.value, 12)}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            メンバー14
                        </Form.Label>
                        <Col sm="auto">
                            <FormControl
                                placeholder="名前を入力"
                                key="14"
                                onChange={(e) => SettingMember(e.target.value, 13)}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            メンバー15
                        </Form.Label>
                        <Col sm="auto">
                            <FormControl
                                placeholder="名前を入力"
                                key="15"
                                onChange={(e) => SettingMember(e.target.value, 14)}
                            />
                        </Col>
                    </Form.Group>
                </Tab>
            </Tabs>
            <Button id="search_button" variant="primary" onClick={sekigae}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-shuffle" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M0 3.5A.5.5 0 0 1 .5 3H1c2.202 0 3.827 1.24 4.874 2.418.49.552.865 1.102 1.126 1.532.26-.43.636-.98 1.126-1.532C9.173 4.24 10.798 3 13 3v1c-1.798 0-3.173 1.01-4.126 2.082A9.624 9.624 0 0 0 7.556 8a9.624 9.624 0 0 0 1.317 1.918C9.828 10.99 11.204 12 13 12v1c-2.202 0-3.827-1.24-4.874-2.418A10.595 10.595 0 0 1 7 9.05c-.26.43-.636.98-1.126 1.532C4.827 11.76 3.202 13 1 13H.5a.5.5 0 0 1 0-1H1c1.798 0 3.173-1.01 4.126-2.082A9.624 9.624 0 0 0 6.444 8a9.624 9.624 0 0 0-1.317-1.918C4.172 5.01 2.796 4 1 4H.5a.5.5 0 0 1-.5-.5z" />
                <path d="M13 5.466V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192zm0 9v-3.932a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192z" />
            </svg> 席替え実行 </Button>
        </>
    )
}

export default Settings;