import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar } from 'react-bootstrap';
import '../App.css';

const Header = () => {
    return (
        <Navbar className="header_style" variant="light" fixed="top" expand="md">
            <Navbar.Brand className="header_title">席替えくん</Navbar.Brand>
        </Navbar>
    );
};

export default Header;