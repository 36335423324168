import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import Seki from "../components/seki";
import { Modal } from 'react-bootstrap';

const SekiBack = ({ zaseki, show, setShow }) => {


    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    席替え結果
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    zaseki.map((table, i) =>
                        <>
                            <div>テーブル{i+1}（{table.length}人席）</div>
                            <div key={i} className="seki_back">
                                {table.map((member, j) => <Seki key={j} name={member} />)}
                            </div>
                        </>
                    )
                }
            </Modal.Body>
        </Modal>
    )
}


export default SekiBack;