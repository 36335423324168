import React, { useState } from 'react';
import './App.css';
import Settings from "./components/settings";
import Header from "./components/header";
import SekiBack from "./components/seki_back";
import MyAlert from "./components/myalert";

function App() {

  const [number, setNumber] = useState(2);
  const [zaseki, setZaseki] = useState([]);
  const [member, setMember] = useState(new Array(15).fill());
  const [show, setShow] = useState(false);
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [table, setTable] = useState(1);

  function sekigae() {
    setAlert(false);
    if (number !== countValidElements(member.slice(0, number))) {
      setAlert(true);
      setMessage("指定された人数と入力されたメンバーの人数が合っていません")
    }
    else if (number < table) {
      setAlert(true);
      setMessage("指定された人数よりテーブルの数が多くなっています")
    }
    else {
      let shuffle = shuffleArray(member.slice(0, number));
      let hamayama_zaseki = hamayama(shuffle);
      setZaseki(allocatePeopleToTables(hamayama_zaseki, table));

      while (hamayama_zaseki === -1) {
        shuffle = shuffleArray(member.slice(0, number));
        hamayama_zaseki = hamayama(shuffle)
        setZaseki(allocatePeopleToTables(hamayama_zaseki, table));
      }

      setShow(true); //座席表はモーダルで表示
    }
  }

  function allocatePeopleToTables(names, tableCount) {
    const allocation = [];
  
    for (let i = 0; i < tableCount; i++) {
      allocation.push([]);
    }
  
    let currentIndex = 0;
    const peopleCount = names.length;
    const peoplePerTable = Math.floor(peopleCount / tableCount);
    let remainingPeople = peopleCount % tableCount;
  
    for (let i = 0; i < tableCount; i++) {
      for (let j = 0; j < peoplePerTable; j++) {
        allocation[i].push(names[currentIndex]);
        currentIndex++;
      }
  
      if (remainingPeople > 0) {
        allocation[i].push(names[currentIndex]);
        currentIndex++;
        remainingPeople--;
      }
    }
  
    return allocation;
  }


  function countValidElements(arr) { //配列の無効な値を無視して要素数を教えてくれる有能関数
    let count = 0;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] !== undefined && arr[i] !== null) {
        count++;
      }
    }
    return count;
  }

  function shuffleArray(array) { //いったんランダムで座席を決める
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  function hamayama(array) {

    const hamano = findIndexesWithSubstring(array, "浜野"); //浜野さんの場所
    const yamamoto = findIndexesWithSubstring(array, "山本"); //山本さんの場所
    const nishimura = findIndexesWithSubstring(array, "西村"); //西村さんの場所
    const kojima = findIndexesWithSubstring(array, "兒島"); //兒島さんの場所

    if ((hamano === -1 || yamamoto === -1 || Math.abs(hamano - yamamoto) >= Math.round(number / 2)) && (hamano === -1 || kojima === -1 || Math.abs(hamano - kojima) >= Math.round(number / 2)) && (nishimura === -1 || kojima === -1 || Math.abs(nishimura - kojima) >= Math.round(number / 2))) { //それぞれの人がいるか，いても適度な距離にいるか
      return array; //浜野＆山本，浜野＆兒島，西村＆兒島が適度な距離にいる座席
    }
    else {
      return -1;
    }
  }

  function findIndexesWithSubstring(arr, substring) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].includes(substring)) {
        return i; // 特定の文字列が見つかったらそのインデックスを返す
      }
    }
    return -1; // 見つからなかった場合は-1を返す
  }

  function SettingMember(name, Mindex) { //名前が入力されたら配列を書き換える
    setMember(member.map((member, index) => (index === Mindex ? name : member)))
  }

  return (
    <React.Fragment>
      <Header />
      <MyAlert
        message={message}
        alert={alert}
        setAlert={setAlert}
      />
      <Settings
        SettingMember={SettingMember}
        setNumber={setNumber}
        sekigae={sekigae}
        setTable={setTable}
      />
      <SekiBack
        zaseki={zaseki}
        show={show}
        setShow={setShow}
      />
    </React.Fragment>
  );
}

export default App;
