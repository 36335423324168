import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import { Alert } from 'react-bootstrap';

const MyAlert = ({ alert, setAlert, message }) => {

    return (
        <Alert variant="danger" show={alert} onClose={() => setAlert(false)} dismissible>
            {message}
        </Alert>
    )
}


export default MyAlert;